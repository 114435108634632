import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApi + "live";

export const LiveSelectByToken = userToken => {
  const criterion = { userToken };

  return axios
    .post(proxyServer + "/get-by-token", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const RenewalHistory = () => {

  const url = 'https://script.google.com/macros/s/AKfycbxDLZraaZ47yLpwOypIBQdegweZbVRrKvlq5lzoVUG0nG6qSR0uyecExvTgGCLwMIlB/exec';

  return axios
    .get(url, { })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });

};
