import React, { Fragment } from "react";
import { Row, Table } from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";
import LocationName from "../../components/LocationName";
import CsvDownloader from "../../components/CsvDownloader";
import IntlMessages from "../../helpers/IntlMessages";

const headers = [
  { label: "Vehicle", key: "vehicleName" },
  { label: "Day", key: "summaryDayText" },
  { label: "Time", key: "startTimeText" },
  { label: "Total Seconds", key: "totalSeconds" },
  { label: "Limit", key: "speedLimit" },
  { label: "Speed", key: "topSpeed" },
  { label: "place", key: "place" },
  { label: "Driver", key: "driverName" },
  { label: "Map place", key: "geo" },
];

class OverSpeed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      theRows: [],
    };
  }

  propagateRows = (allRows) => {
    const theRows = [];

    // console.log('the over speeds ', allRows);

    allRows.forEach((x) => {
      x.overSpeeds.forEach((el) => {
        theRows.push({
          vehicleName: el.vehicleName,
          summaryDayText: el.summaryDayText,
          totalSeconds: el.totalSeconds,
          topSpeed: el.topSpeed,
          speedLimit: el.speedLimit,
          place: el.place,
          startTimeText: el.startTimeText,
          duration: el.duration,
          // driverName: el.driverName,
          driverName: el.driverName ? el.driverName : el.driverItem? `Driver ${el.driverItem.rfid_code}` : '',
          geo: el.geo,
        });
      });
    });


    const sortedBySpeed = theRows.sort((a, b) => {
      return b.topSpeed - a.topSpeed;
    });

    this.setState({ theRows: sortedBySpeed });
  };

  componentDidMount() {
    this.propagateRows(this.props.allRows);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.allRows !== this.props.allRows) {
      this.propagateRows(this.props.allRows);
    }
  }

  render() {
    const showRows = this.state.theRows || [];
    return (
      <Row>
        <Colxx xxs="12">
          <span className="float-right">
            <CsvDownloader
              headers={headers}
              filename={"ctraac-overspeed.csv"}
              data={this.state.theRows}
            />
          </span>
        </Colxx>
        <Colxx xxs="12">
          <Table responsive hover bordered striped>
            <thead>
              <tr>
                <th>
                  <IntlMessages id="day-summary.vehicle" />
                </th>
                <th>
                  <IntlMessages id="day-summary.day" />
                </th>
                <th>
                  <IntlMessages id="day-summary.time" />
                </th>
                <th>
                  <IntlMessages id="day-summary.total-seconds" />{" "}
                </th>
                <th>
                  <IntlMessages id="day-summary.limit" />
                </th>
                <th>
                  <IntlMessages id="day-summary.speed" />{" "}
                </th>
                <th>
                  <IntlMessages id="day-summary.place" />
                </th>
                <th>
                  <IntlMessages id="day-summary.driver" />
                </th>
              </tr>
            </thead>
            <tbody>
              {showRows.map((item, i) => (
                <Fragment key={i}>
                  <tr>
                    <td>{item.vehicleName}</td>
                    <td> {item.summaryDayText} </td>
                    <td> {item.startTimeText} </td>
                    <td> {item.totalSeconds}</td>
                    <td> {item.speedLimit}</td>
                    <td> {item.topSpeed}</td>
                    <td>
                      <LocationName
                        place={item.place}
                        geo={item.geo}
                        objectItem={item}
                        showGoogle
                        onClick={() =>
                          this.props.onShowMapModel(item.geo, item.place)
                        }
                      />
                    </td>
                    <td> {item.driverName}</td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </Table>
        </Colxx>
      </Row>
    );
  }
}

export default OverSpeed;
