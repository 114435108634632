import React from "react";
import { Button, Row, CardTitle, Badge, ButtonGroup } from "reactstrap";
import { CSVLink } from "react-csv";
import moment from "moment";

import { AppHelperDateAndTimeToString } from "../helpers/AppHelper";
import IntlMessages from "../helpers/IntlMessages";
import { Colxx } from "../components/common/CustomBootstrap";
import LocationName from "../components/LocationName";

const pagingLimit = -1;
const headers = [
  { label: "Plate", key: "plateNumber" },
  { label: "Asset", key: "assetNumber" },
  { label: "Cluster", key: "cluster" },
  { label: "Model", key: "model" },
  { label: "GpsTime", key: "gpsTime" },
  { label: "GeoPoint", key: "geoPoint" },
  { label: "Fuel", key: "fuel" },
  { label: "UpdateTime", key: "updateTime" },
  { label: "Driver name", key: "driverName" },
  { label: "Ignition", key: "ignition" },
  { label: "Status", key: "status" },
  { label: "Device Id", key: "deviceId" },
  { label: "Sim Provider", key: "simProvider" },
  { label: "Sim No", key: "sim" },
  { label: "Sim No2", key: "sim2" },
  { label: "Installation", key: "installation" },
  { label: "Renewed", key: "renewed" },
  { label: "Expiry", key: "expiry" },
  { label: "Reminig Days", key: "reminigDays" },
  { label: "Renewal Interval", key: "renewalIn" },
  { label: "Groups", key: "groupNames" },
];

function clearText(text) {
  const text2 = (text || "").toString().trim();
  return text2.replace(/[^a-zA-Z0-9 ]/g, "");
}

class LiveStatusData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allRows: [],
      filteredRows: [],
      totalCount: 0,

      limit: pagingLimit,
      skip: 0,
      fileName: "Ctraac - live_status.csv",
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.allRows !== this.props.allRows) {
      const allRows = this.attachRenewalHistory();

      this.setState({
        allRows: allRows,
        filteredRows: allRows,
      });

      this.attachRenewalHistory();
    }
  }

  attachRenewalHistory = () => {
    const vehicleRenewalHistory = this.props.vehicleRenewalHistory || [];
    const allRows = this.props.allRows || [];

    const attachedAllRows = allRows.map((item) => {
      const historyItem = vehicleRenewalHistory.filter(
        (el) => clearText(el.Vehicle || "") === clearText(item.plateNumber)
      );

      return { ...item, vehicleRenewalHistory: historyItem };
    });

    console.log("attachedAllRows", attachedAllRows);

    return attachedAllRows;
  };

  render() {
    return (
      <React.Fragment>
        {this.props.selectedVehicles.length && this.props.touched ? (
          <CardTitle>
            <IntlMessages id="report.live-status" />

            {AppHelperDateAndTimeToString(moment())}
          </CardTitle>
        ) : (
          <CardTitle>
            <IntlMessages id="report.try-search" />
          </CardTitle>
        )}

        {this.props.touched && !this.props.totalCount ? (
          <p>
            <IntlMessages id="report.nothing-show" />
          </p>
        ) : (
          ""
        )}
        <div className="card-body-min-height">
          <div className="float-right">
            <ButtonGroup className="mr-1">
              <Button
                outline
                size="xs"
                color="primary"
                onClick={() => {
                  this.setState({ filteredRows: this.state.allRows });
                }}
              >
                All
              </Button>
              <Button
                outline
                size="xs"
                color="primary"
                onClick={() => {
                  this.setState({
                    filteredRows: this.state.allRows.filter(
                      (el) => el.reminigDays < 0
                    ),
                  });
                }}
              >
                Expired
              </Button>
              <Button
                outline
                size="xs"
                color="primary"
                onClick={() => {
                  this.setState({
                    filteredRows: this.state.allRows.filter(
                      (el) => el.status === "No data"
                    ),
                  });
                }}
              >
                No data
              </Button>
              <Button
                outline
                size="xs"
                color="primary"
                onClick={() => {
                  this.setState({
                    filteredRows: this.state.allRows.filter(
                      (el) => el.status === "Connected"
                    ),
                  });
                }}
              >
                Connected
              </Button>
            </ButtonGroup>

            <CSVLink
              headers={headers}
              filename={this.state.fileName}
              data={this.state.filteredRows}
              enclosingCharacter={`'`}
            >
              <Button
                title={this.state.fileName}
                size="xs"
                outline
                color="dark"
              >
                <IntlMessages id="common.download" />
              </Button>
            </CSVLink>
          </div>
          {this.state.filteredRows.length + " "}
          <IntlMessages id="report.records" />

          <Row className={"mt-2"}>
            <Colxx xxs="12"></Colxx>
          </Row>

          {this.state.filteredRows.map((item, i) => (
            <Row
              key={this.state.skip + i + 1}
              className={`border-bottom p-2 ${i % 2 ? `bg-light` : ``}`}
            >
              <Colxx xl="3" lg="3" md="12" sm="12">
                {this.state.skip + i + 1}

                <img
                  title={item.manufacture_name}
                  className="app-select-icon"
                  src={item.manufactureIcon}
                  alt="icon"
                />
                <img
                  title={item.typeText}
                  className="app-select-icon"
                  src={item.typeIcon}
                  alt="icon"
                />

                {`${item.plateNumber} ${item.assetNumber} ${item.model} ${item.cluster}`}
              </Colxx>

              <Colxx xl="3" lg="3" md="12" sm="12">
                <Badge
                  color={
                    item.status === "Connected"
                      ? `primary`
                      : item.status === "Expired"
                      ? `warning`
                      : `info`
                  }
                  className="mb-1 mr-1"
                >
                  {item.status}
                </Badge>

                {item.reminigDays < 0 ? (
                  <small className="bg-yellow">
                    Expired since {Math.abs(item.reminigDays)} days
                  </small>
                ) : null}
              </Colxx>

              <Colxx xl="3" lg="3" md="12" sm="12">
                <u>
                  <b>GPS:</b>
                  {item.gpsTime}
                  {" | "}
                  <LocationName
                    place={item.place}
                    geo={item.geoPoint}
                    objectItem={item}
                    showGoogle
                  />
                </u>
              </Colxx>

              <Colxx xl="3" lg="3" md="12" sm="12">
                <u>
                  <React.Fragment key="installation">
                    <b>Installation:</b>

                    {item.installation}
                  </React.Fragment>
                  {item.renewed ? (
                    <React.Fragment key="renewed">
                      <br />
                      <b>Renewed:</b>
                      {item.renewed}
                    </React.Fragment>
                  ) : null}
                </u>
              </Colxx>
              <Colxx xl="3" lg="3" md="12" sm="12">
                <u>
                  <b>Device:</b>
                  {item.deviceId}
                </u>
              </Colxx>

              <Colxx xl="3" lg="3" md="12" sm="12">
                <u>
                  <b>Sim:</b>
                  {item.sim}
                  {" | "}
                  {item.simProvider}
                </u>
              </Colxx>

              <Colxx xl="3" lg="3" md="12" sm="12">
                <u>
                  <b>Last update: </b>
                  {item.isLastUpdateToday
                    ? `Today ${item.isLastUpdateToday}`
                    : item.updateTime}
                </u>
              </Colxx>
              <Colxx xl="3" lg="3" md="12" sm="12">
                <u>
                  <b>Expiry:</b>
                  {`${item.expiry} | ${item.renewalIn} | ${item.reminigDays}`}
                </u>
              </Colxx>
              <Colxx xl="3" lg="3" md="12" sm="12">
                <u>
                  <b>Groups:</b>

                  {item.groupNames}
                </u>
              </Colxx>

              <Colxx xl="3" lg="3" md="12" sm="12">
                <u>
                  <b>Webid:</b>

                  {item.webId}
                </u>
              </Colxx>

              <Colxx xl="12" lg="12" md="12" sm="12">
                {item.vehicleRenewalHistory.length === 0 ? null : (
                  <React.Fragment key={i}>
                    <br />
                    <b>Renewal History:</b>
                    <ol>
                      {item.vehicleRenewalHistory.map((el, i) => (
                        <React.Fragment key={i}>
                          <li>
                            Date: {moment(el.Date).format("DD-MMM-yyyy")} | Bill
                            Number: {el["Bill Number"]} | Phone:{" "}
                            {el["phone number"]}{" "}
                            
                            {item.vehicleRenewalHistory.length - 1 === i? (
                            <span>| subscription validity {moment(el.Date).format("DD-MMM-yyyy")} to {item.expiry} </span>
                            ): null}
                          </li>
                        </React.Fragment>
                      ))}
                    </ol>
                  </React.Fragment>
                )}
              </Colxx>
            </Row>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default LiveStatusData;
